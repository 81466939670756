<template>
  <v-app-bar
      relative
      color="#6A76AB"
      dark
  >
    <template v-slot:img="{ props }">
      <v-img
          v-bind="props"
          gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"
      ></v-img>
    </template>


    <v-app-bar-title>{{ settings.pageName }}</v-app-bar-title>

    <v-spacer></v-spacer>

    <v-btn v-for="(action, index) in settings.actions" :key="`header-action-${index}`" @click="action.onClick"
           :title="action.title"
           icon>
      <v-icon>{{ action.icon }}</v-icon>
    </v-btn>

    <v-list rounded color="transparent" dense
            class="p-0 d-flex align-items-center"
            style="height: 48px"
    >
      <v-list-item-group
          v-model="selectedItem"
          color="grey lighten-4"
          class="d-flex"
      >
        <v-list-item
            v-for="(item, i) in items"
            :key="i" :link="true" :to="item.url"
            class="mb-0"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

  </v-app-bar>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "HeaderComponent",
  props: {toggleDrawer: Function},
  data: () => ({
    selectedItem: 1,
  }),
  computed: {
    ...mapState({
      settings: state => state.layout.header
    }),
    items: () => [
      {title: 'Offers', icon: 'mdi-tag', url: '/offers'},
      {title: 'Domains', icon: 'mdi-web', url: '/domains'},
    ]
  },
}
</script>

<style scoped>

</style>