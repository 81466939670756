<template>
  <div>
    <header-component :toggleDrawer="() => (drawer = !drawer)" />
    <!-- <v-navigation-drawer
        v-model="drawer"
        absolute
        bottom
        temporary
    >
      <aside-menu />
    </v-navigation-drawer> -->
    <v-sheet>
      <router-view />
    </v-sheet>
  </div>
</template>


<script>
import { mapMutations, mapState } from "vuex";
// import AsideMenu from "../components/AsideMenu";
import HeaderComponent from "../components/Header";
import { SET_FILTER } from "../store/modules/page/types";
import { buildFilterString } from "../services/services";

export default {
  name: "AuthPage",
  components: { HeaderComponent },
  data: () => ({
    drawer: false,
    group: null,
  }),

  computed: {
    ...mapState({
      isAuthenticated: (state) => state.auth.isAuthenticated,
      default_filter: (state) => state.page.default_filter,
      filter: (state) => state.page.filter,
    }),
  },

  methods: {
    ...mapMutations({
      setPage: `page/${SET_FILTER}`,
    }),
    changePage: function () {
      let filter = this.$route.query;

      this.setPage({
        filter: { ...filter },
      });
    },
  },

  watch: {
    group() {
      this.drawer = false;
    },
    $route(newValue, oldValue) {
      if (newValue.name !== oldValue.name) {
        this.changePage();
      }
    },
    filter(newValue) {
      //обновляю query толко когда filter не равно query и filter не задан по умолчанию
      if (
        !this.default_filter &&
        buildFilterString(newValue) !== buildFilterString(this.$route.query)
      ) {
        this.$router.push({ query: newValue });
      }
    },
  },

  mounted() {
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }
  },

  created: function () {
    this.changePage();
  },
};
</script>

<style scoped>
</style>